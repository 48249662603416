// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-log-index-tsx": () => import("./../../../src/pages/log/index.tsx" /* webpackChunkName: "component---src-pages-log-index-tsx" */),
  "component---src-pages-log-short-diary-tsx": () => import("./../../../src/pages/log/short-diary.tsx" /* webpackChunkName: "component---src-pages-log-short-diary-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-short-diary-tsx": () => import("./../../../src/templates/short-diary.tsx" /* webpackChunkName: "component---src-templates-short-diary-tsx" */),
  "component---src-templates-wiki-tsx": () => import("./../../../src/templates/wiki.tsx" /* webpackChunkName: "component---src-templates-wiki-tsx" */)
}

